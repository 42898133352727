import { JSX } from 'react';
import {
  EBlockchainNetwork,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import EthereumNetworkIcon from 'assets/blockchain-network-icons/ethereum.icon.svg?react';
import StellarNetworkIcon from 'assets/blockchain-network-icons/stellar.icon.svg?react';
import TronNetworkIcon from 'assets/blockchain-network-icons/tron.icon.svg?react';
import ActionRequiredIcon from 'assets/transaction-status-icons/action-required.icon.svg?react';
import CompletedIcon from 'assets/transaction-status-icons/completed.icon.svg?react';
import ErrorIcon from 'assets/transaction-status-icons/error.icon.svg?react';
import IncompleteIcon from 'assets/transaction-status-icons/incomplete.icon.svg?react';
import PendingIcon from 'assets/transaction-status-icons/pending.icon.svg?react';
import RefundedIcon from 'assets/transaction-status-icons/refunded.icon.svg?react';

const pendingStatusData = {
  label: 'transaction.statuses.pending',
  icon: <PendingIcon />,
  className: 'pending',
};

const actionRequiredStatusData = {
  icon: <ActionRequiredIcon />,
  className: 'action-required',
  label: 'transaction.statuses.actionRequired',
};

export const TRANSACTION_STATUSES_MAPPING = {
  [ETransactionStatus.Incomplete]: {
    label: 'transaction.statuses.incomplete',
    icon: <IncompleteIcon />,
    className: ETransactionStatus.Incomplete,
  },
  [ETransactionStatus.PendingUserTransferComplete]: pendingStatusData,
  [ETransactionStatus.PendingExternal]: pendingStatusData,
  [ETransactionStatus.PendingAnchor]: pendingStatusData,
  [ETransactionStatus.PendingStellar]: pendingStatusData,
  [ETransactionStatus.PendingTrust]: pendingStatusData,
  [ETransactionStatus.PendingUserTransferStart]: actionRequiredStatusData,
  [ETransactionStatus.PendingUser]: actionRequiredStatusData,
  [ETransactionStatus.Completed]: {
    label: 'transaction.statuses.completed',
    icon: <CompletedIcon />,
    className: ETransactionStatus.Completed,
  },
  [ETransactionStatus.Refunded]: {
    label: 'transaction.statuses.refunded',
    icon: <RefundedIcon />,
    className: ETransactionStatus.Refunded,
  },
  [ETransactionStatus.Expired]: {
    label: 'transaction.statuses.expired',
    icon: <IncompleteIcon />,
    className: ETransactionStatus.Expired,
  },
  [ETransactionStatus.Error]: {
    label: 'transaction.statuses.error',
    icon: <ErrorIcon />,
    className: ETransactionStatus.Error,
  },
  default: {
    label: '',
    icon: <IncompleteIcon />,
    className: 'default-status',
  }
} as Record<ETransactionStatus | 'default', { label: string; icon: JSX.Element; className: string }>;

export const BLOCKCHAIN_NETWORK_MAPPING = {
  [EBlockchainNetwork.Stellar]: {
    label: 'Stellar',
    icon: <StellarNetworkIcon />,
  },
  [EBlockchainNetwork.Tron]: {
    label: 'Tron',
    icon: <TronNetworkIcon />,
  },
  [EBlockchainNetwork.Ethereum]: {
    label: 'Ethereum',
    icon: <EthereumNetworkIcon />,
  },
};
