import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import { mainStore } from 'common/clients/jotai-store.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import routerTree from 'navigation/routerTree.tsx';

export interface IReqOptions {
  from: string,
}

interface IInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  from?: string;
}

export const apiClient = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { indices: false }),
  },
});

const apiClientInterceptorsReq = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => ({
  ...config,
});

const apiClientInterceptorsReqError = async (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const apiClientInterceptorsRes = (response: AxiosResponse): AxiosResponse => response;

const apiClientInterceptorsResError = async (error: AxiosError): Promise<AxiosError> => {
  const { response } = error;
  const searchParams = new URLSearchParams();
  const config = error.config as IInternalAxiosRequestConfig;

  if (response?.status === 401) {
    const from = config.from ?? window.location.pathname;
    searchParams.append(EUrlQueryParams.ReturnTo, from);

    mainStore.set(profileConfigAtom, () => ({
      userIsAuthorized: false,
    }));
    queryClient.clear();

    await routerTree.navigate(`${ROUTES_MAPPING.PUBLIC.LOGIN}?${searchParams.toString()}`);
  }

  return Promise.reject(error);
};

apiClient.interceptors.request.use(apiClientInterceptorsReq, apiClientInterceptorsReqError);
apiClient.interceptors.response.use(apiClientInterceptorsRes, apiClientInterceptorsResError);
