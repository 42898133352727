import { LoaderFunctionArgs } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { IErrorPageLoaderData } from './interfaces/error.page.interfaces.ts';

export const errorPageLoader = async ({ request }: LoaderFunctionArgs): Promise<IErrorPageLoaderData | Response> => {
  try {
    const reqOptions = {
      from: request.url,
    };
    const urlSearchParams = new URL(request.url).searchParams;
    const interactiveIdQueryParamValue = urlSearchParams.get(EUrlQueryParams.InteractiveId) ?? '';

    let transactionInfoData: ITransactionInfoDataRes | Record<string, never> = {};

    if (interactiveIdQueryParamValue) {
      const transactionDataQuery =
        queryKeysClient[EQueryKeys.TransactionKeys].getTransactionInfoData({
          sep24GUID: interactiveIdQueryParamValue,
          reqOptions,
        });
      transactionInfoData =
        (await queryClient.getQueryData(transactionDataQuery.queryKey)) ??
        (await queryClient.fetchQuery(transactionDataQuery));
    }
    return { transactionInfoData };
  } catch (e: unknown) {
    const error = e as {
      response: {
        status: number;
      };
    };

    captureError(error as IError);

    return {
      transactionInfoData: {},
    };
  }
};
