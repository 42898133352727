import { AxiosRequestConfig } from 'axios';
import { apiClient, IReqOptions } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { IUserWalletsDataRes, IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';

export const getUserWalletsDataService = async ({
    reqOptions,
  }: {
  reqOptions?: IReqOptions,
}): Promise<IUserWalletsDataRes> => {
  const url = API_ENDPOINTS.WALLET.WALLETS;
  const config = {
    ...reqOptions,
  } as AxiosRequestConfig;
  const res = await apiClient.get<IUserWalletsDataRes>(url, config);

  return res.data;
};

export const getWalletDataService = async ({
  reqOptions,
}: {
  reqOptions?: IReqOptions,
}): Promise<IWalletDataRes> => {
  const url = API_ENDPOINTS.WALLET.WALLET;
  const config = {
    ...reqOptions,
  } as AxiosRequestConfig;
  const res = await apiClient.get<IWalletDataRes>(url, config);

  return res.data;
};
