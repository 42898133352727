import { createQueryKeys } from '@lukemorales/query-key-factory';
import { IReqOptions } from 'common/clients/api.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { IUserWalletsDataRes, IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { getUserWalletsDataService, getWalletDataService } from 'domains/wallet/services/wallet.service.ts';

export const walletQueryKeys = createQueryKeys(EQueryKeys.WalletKeys, {
  getUserWalletsData: ({
    reqOptions,
  }: {
    reqOptions?: IReqOptions,
  }) => ({
    queryKey: ['get-user-wallets-data'],
    queryFn: (): Promise<IUserWalletsDataRes> => getUserWalletsDataService({ reqOptions }),
  }),
  getWalletData: ({
    reqOptions,
  }: {
    reqOptions?: IReqOptions,
  }) => ({
    queryKey: ['get-wallet-data'],
    queryFn: (): Promise<IWalletDataRes> => getWalletDataService({ reqOptions }),
  }),
});
