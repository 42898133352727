import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { useGetFlowErrorQuery } from 'domains/auth/queries/auth.query.ts';
import { ETransactionStatus } from 'domains/transaction/enums/transaction.enums.ts';
import { EErrorPageTypeError } from 'pages/error/enums/error.page.enums.ts';
import { SButtonWrapper, SContent, SDescription, STitle, SWarningIcon } from 'pages/error/error.page.styles.ts';
import { FILTER_PENDING_STATUS_MAPPING } from 'pages/transactions/constants/transactions.constants.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import { IErrorPageLoaderData, IErrorPageLocationState } from './interfaces/error.page.interfaces.ts';

const ErrorPage = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation() as IErrorPageLocationState;
  const { state: locationState } = location;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { transactionInfoData } = useLoaderData() as IErrorPageLoaderData;
  const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo);
  // const codeQueryParamValue = searchParams.get(EUrlQueryParams.Code');
  const messageQueryParamValue = searchParams.get(EUrlQueryParams.Message);
  const titleQueryParamValue = searchParams.get(EUrlQueryParams.Title);
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id);
  const [showButton, setShowButton] = useState<boolean>(true);

  const ERROR_TYPES_MAPPING = {
    [EErrorPageTypeError.LastPaymentInProgress]: {
      title: 'error.mapping.lastPaymentInProgress.title',
      message: 'error.mapping.lastPaymentInProgress.message',
      btnLabel: 'error.mapping.lastPaymentInProgress.btnLabel',
      btnClickHandler: (): void => {
        const status = `${FILTER_PENDING_STATUS_MAPPING.toString()},${ETransactionStatus.PendingUser}`;
        navigate(`${ROUTES_MAPPING.PROTECTED.TRANSACTIONS}?status=${status}`);
      },
    }
  };

  const errorMappingData = locationState?.type && ERROR_TYPES_MAPPING[locationState.type];

  const { data: kratosErrorData } = useGetFlowErrorQuery(
    idQueryParamValue ?? '',
    {
      enabled: !!idQueryParamValue,
    }
  );

  useEffect(() => {
    if (transactionInfoData.postMessage === 'false') {
      setShowButton(false);
    }
  }, [transactionInfoData]);

  if (kratosErrorData) {
    console.error('Kratos Error Data: ', kratosErrorData);
  }

  const returnBtnClickHandler = (): void => {
    window.location.replace(setHttps(returnToQueryParamValue ?? ''));
  };

  const title = errorMappingData?.title ?? titleQueryParamValue ?? 'error.title';
  const message = errorMappingData?.message ?? messageQueryParamValue ?? 'error.description';
  const btnLabel = errorMappingData?.btnLabel ?? 'error.btn.return';

  return (
    <>
      <SContent>
        <SWarningIcon />
        <STitle>{t(title)}</STitle>
        <SDescription className="p2">{t(message)}</SDescription>
      </SContent>
      <SButtonWrapper>
        {showButton && (
          <Button
            onClick={errorMappingData?.btnClickHandler ?? returnBtnClickHandler}
            block
          >
            {t(btnLabel)}
          </Button>
        )}
      </SButtonWrapper>
    </>
  );
};

export default ErrorPage;
