import { atom } from 'jotai';

interface IHeaderConfigAtomData {
  backLink?: string | number | (() => void);
  title?: string | null | false; // In sase of 'false' don't show title
  isMenuVisible?: boolean;
}

export const headerConfigDefaultValue: IHeaderConfigAtomData = {
  title: null,
  backLink: -1,
  isMenuVisible: true,
};

const headerConfigOriginAtom =
  atom<IHeaderConfigAtomData>(headerConfigDefaultValue);

export const headerConfigAtom = atom(
  (get) => get(headerConfigOriginAtom),
  (get, set, data: IHeaderConfigAtomData) => {
    const originData = get(headerConfigOriginAtom);
    const mergedData = {
      ...originData,
      ...data,
    } as IHeaderConfigAtomData;

    set(headerConfigOriginAtom, mergedData);
  },
);
