import styled from 'styled-components';
import RateArrowIcon from 'assets/rate-arrow.icon.svg?react';

export const SRatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 8px;
`;

export const SRateArrowIcon = styled(RateArrowIcon)`
  path {
    stroke: ${props => props.theme.themeV3.colors.grey700};
  }
`;

export const SValue = styled.div`
  font-family: "Plus Jakarta Sans", sans-serif;
  color: ${props => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
