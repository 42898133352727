import { Input } from 'antd';
import styled from 'styled-components';

export const SInputWrapper = styled.div`
  position: relative;
`;

export const SInput = styled(Input)<{ isClear: boolean }>`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.themeV3.colors.grey700};
  background: ${props => props.theme.themeV3.colors.grey200} !important;
  border-radius: 8px;
  border: 0;
  padding: ${props => props.isClear ? '16px 30px 16px 16px' : '16px'};

  input::placeholder {
    color: ${props => props.theme.themeV3.colors.grey400};
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: 0;
    color: ${props => props.theme.themeV3.colors.grey700};
    background: ${props => props.theme.themeV3.colors.grey200};
  }
  
  &.ant-input-disabled {
    color: ${props => props.theme.themeV3.colors.grey300};
    background: ${props => props.theme.themeV3.colors.grey200};

    input::placeholder {
      color: ${props => props.theme.themeV3.colors.grey300};
    }
  }
  
  &.ant-input-status-error {
    background: ${props => props.theme.themeV3.colors.red100} !important;
    border-color: ${props => props.theme.themeV3.colors.red300} !important;
    margin-bottom: 4px;

    input::placeholder {
      color: ${props => props.theme.themeV3.colors.red200} !important;
    }
  }

   .ant-input-clear-icon {
     display: none;
   }
`;

export const SInputClearIconWrapper = styled.div`
  width: auto;
  height: 12px;
  line-height: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    height: 12px;
    width: auto;

    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    }
  }
`;
