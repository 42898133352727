import { ReactElement, useEffect } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import ThemeMainMenuScreen from 'common/components/main-menu/screens/theme.menu-screen.tsx';
import { mainMenuBackBtnAtom, mainMenuScreenAtom } from 'common/stores/main-menu.store.ts';
import { EScreens } from './enums/main.menu.enums.ts';
import {
  SCloseIconWrapper,
  SMainMenuContainer,
  SMainMenuHeader,
  SMainMenuHeaderBackIcon,
  SMainMenuHeaderBackIconWrapper,
  SMainMenuHeaderCloseIcon,
  SMainMenuHeaderLabel,
} from './main-menu.component.styles.ts';
import LanguagesMainMenuScreen from './screens/language.menu-screen.tsx';
import LegalAndPoliciesMenuScreen from './screens/legal-and-policies.menu-screen.tsx';
import MainMenuScreen from './screens/main.menu-screen.tsx';

const MainMenu = (): ReactElement | null => {
  const [selectedScreen, setMainMenuScreen] = useAtom(mainMenuScreenAtom);
  const [isBackBtn, setMainMenuBack] = useAtom(mainMenuBackBtnAtom);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedScreen === EScreens.Main) {
      setMainMenuBack(null);
    }
  }, [selectedScreen, setMainMenuBack]);

  const MENU_SCREENS = {
    [EScreens.Main]: {
      headerLabel: 'mainMenu.screen.main.header.label',
      component: <MainMenuScreen />,
    },
    [EScreens.Language]: {
      headerLabel: 'mainMenu.screen.language.header.label',
      component: <LanguagesMainMenuScreen />,
    },
    [EScreens.LegalAndPolicies]: {
      headerLabel: 'mainMenu.screen.legalAndPolicies.header.label',
      component: <LegalAndPoliciesMenuScreen />,
    },
    [EScreens.Theme]: {
      headerLabel: 'mainMenu.screen.theme.header.label',
      component: <ThemeMainMenuScreen />,
    },
  };

  if (selectedScreen) {
    const isСloseIconVisible = !(
      selectedScreen === EScreens.Language ||
      selectedScreen === EScreens.LegalAndPolicies ||
      selectedScreen === EScreens.Theme
    );

    return (
      <SMainMenuContainer>
        <SMainMenuHeader>
          <SMainMenuHeaderBackIconWrapper>
            {isBackBtn && (
              <SMainMenuHeaderBackIcon
                onClick={() => {
                  setMainMenuScreen(isBackBtn);
                }}
              />
            )}
          </SMainMenuHeaderBackIconWrapper>
          <SMainMenuHeaderLabel>{t(MENU_SCREENS[selectedScreen].headerLabel)}</SMainMenuHeaderLabel>
          <SCloseIconWrapper>
            {isСloseIconVisible && (
              <SMainMenuHeaderCloseIcon
                onClick={() => {
                  setMainMenuScreen(null);
                }}
              />
            )}
          </SCloseIconWrapper>
        </SMainMenuHeader>
        {MENU_SCREENS[selectedScreen].component}
      </SMainMenuContainer>
    );
  }

  return null;
};

export default MainMenu;
