import { Session } from '@ory/kratos-client';
import i18n from 'i18next';
import { LoaderFunctionArgs } from 'react-router-dom';
import { mainStore } from 'common/clients/jotai-store.client.ts';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { IUserProfileConfigRes } from 'domains/user/interfaces/user.interfaces.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { APP_CONFIG } from '../../app.config.ts';

export interface INeutralRouteGuardRootLoader {
  getGeneralTransactionInfoData: ITransactionInfoDataRes | undefined;
  getWalletData: IWalletDataRes | undefined;
  getToSessionData: Session | undefined;
  userIsAuthorized: boolean;
  getUserProfileConfigData: IUserProfileConfigRes | undefined;
}

export const neutralRouteGuardRootLoader = async (
  { request }: LoaderFunctionArgs
): Promise<INeutralRouteGuardRootLoader> => {
  let getToSessionData: Session | undefined;
  const reqOptions = {
    from: request.url,
  };

  try {
    const getToSessionQuery = queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession();
    getToSessionData =
      await queryClient.getQueryData(getToSessionQuery.queryKey)
      ?? await queryClient.fetchQuery(getToSessionQuery);
  } catch (e) {
    const error = e as {
      response?: {
        status?: number;
      };
    };

    if (error.response?.status !== 401) {
      captureError(error as IError);
    }
  }

  try {
    const requestUrl = new URL(request.url);
    const { searchParams } = requestUrl;
    const interactiveIdFromUrlQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';

    const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo) ?? '';
    let interactiveIdFromRedirectUrlQueryParamValue = '';

    if (returnToQueryParamValue) {
      const returnUrl = new URL(returnToQueryParamValue);
      const { searchParams: searchParamsReturnUrl } = returnUrl;
      interactiveIdFromRedirectUrlQueryParamValue =
        searchParamsReturnUrl.get(EUrlQueryParams.InteractiveId) ?? '';
    }

    const transactionIDFromLocalStore = localStorage.getItem(ELocalStorageKeys.TransactionGUID);
    const interactiveIdQueryParamValue =
      interactiveIdFromUrlQueryParamValue || interactiveIdFromRedirectUrlQueryParamValue || transactionIDFromLocalStore;

    if (interactiveIdQueryParamValue) {
      localStorage.setItem(ELocalStorageKeys.TransactionGUID, interactiveIdQueryParamValue);
    }

    let getGeneralTransactionInfoData: ITransactionInfoDataRes | undefined;

    if (interactiveIdQueryParamValue) {
      const getGeneralTransactionInfoDataQuery =
        queryKeysClient[EQueryKeys.TransactionKeys].getGeneralTransactionInfoData({
          sep24GUID: interactiveIdQueryParamValue,
          reqOptions,
        });
      getGeneralTransactionInfoData = await queryClient.getQueryData(getGeneralTransactionInfoDataQuery.queryKey)
        ?? await queryClient.fetchQuery(getGeneralTransactionInfoDataQuery);
    }

    const getWalletDataQuery = queryKeysClient[EQueryKeys.WalletKeys].getWalletData({ reqOptions });
    const getWalletData: IWalletDataRes =
      await queryClient.getQueryData(getWalletDataQuery.queryKey)
      ?? await queryClient.fetchQuery(getWalletDataQuery);

    let userIsAuthorized = false;
    let getUserProfileConfigData: IUserProfileConfigRes | undefined;

    if (!(i18n.language in APP_CONFIG.LOCALES)) {
      await i18n.changeLanguage(APP_CONFIG.LOCALE_DEFAULT);
    }

    // Check if user is authorized.
    if (getToSessionData?.active) {
      userIsAuthorized = true;

      const getUserProfileConfigQuery = queryKeysClient[EQueryKeys.UserKeys].getUserProfileConfig({ reqOptions });
      getUserProfileConfigData =
        await queryClient.getQueryData(getUserProfileConfigQuery.queryKey)
        ?? await queryClient.fetchQuery(getUserProfileConfigQuery);

      // Set lang from config
      if (getUserProfileConfigData?.lang && getUserProfileConfigData.lang in APP_CONFIG.LOCALES) {
        await i18n.changeLanguage(getUserProfileConfigData.lang);
      }

      // Set profile data to store
      mainStore.set(profileConfigAtom, (data) => ({
        ...data,
        userIsAuthorized: true,
        profile: getUserProfileConfigData,
      }));
    }

    return {
      getGeneralTransactionInfoData,
      getWalletData,
      getToSessionData,
      userIsAuthorized,
      getUserProfileConfigData,
    };
  } catch (error: unknown) {
    captureError(error as IError);

    return {
      getGeneralTransactionInfoData: undefined,
      getWalletData: undefined,
      getToSessionData: undefined,
      userIsAuthorized: false,
      getUserProfileConfigData: undefined,
    };
  }
};
