import {
  useMutation,
  UseMutationResult,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { IReqOptions } from 'common/clients/api.client.ts';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  ITransactionDataRes,
  ITransactionRefundRes,
  ITransactionsDataRes,
  ITransactionsDataParams,
  ITransactionsCountRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  getTransactionDataService,
  postTransactionRefundService,
} from 'domains/transaction/services/transaction.service.ts';
import { TTransactionsCountReqParams } from 'domains/transaction/types/transaction.type.ts';

interface IGetTransactionInfiniteQueryData {
  pages: ITransactionsDataRes[],
}

export const useGetTransactionsQuery = ({
  reqParams,
  reqOptions,
  queryOptions,
}: {
  reqParams: ITransactionsDataParams;
  reqOptions?: IReqOptions;
  queryOptions?: Omit<
    UseInfiniteQueryOptions<
      ITransactionsDataRes,
      Error,
      IGetTransactionInfiniteQueryData
    >,
    'queryKey'
  >;
}): UseInfiniteQueryResult<IGetTransactionInfiniteQueryData> =>
  useInfiniteQuery<ITransactionsDataRes, Error, IGetTransactionInfiniteQueryData>({
    ...queryOptions,
    ...queryKeysClient[EQueryKeys.TransactionKeys].getTransactionsData({
      reqParams,
      reqOptions,
    }) as unknown as UseInfiniteQueryOptions<
      ITransactionsDataRes,
      Error,
      IGetTransactionInfiniteQueryData
    >,
  });

export const useGetTransactionDataMutation = (): UseMutationResult<
  ITransactionDataRes,
  Error,
  {
    sep24GUID: string;
    reqOptions?: IReqOptions;
  }
> => useMutation({
  mutationFn: ({
    sep24GUID,
    reqOptions,
  }: {
    sep24GUID: string;
    reqOptions?: IReqOptions;
  }) => getTransactionDataService({
    sep24GUID,
    reqOptions,
  }),
});

export const usePostTransactionRefundMutation = (): UseMutationResult<
  ITransactionRefundRes,
  Error,
  {
    id: string,
  }
> => useMutation({
  mutationFn: ({ id }: { id: string }) => postTransactionRefundService(id),
});

export const useCountTransactionsDataQuery = (
  params: TTransactionsCountReqParams,
  options?: Omit<UseQueryOptions<ITransactionsCountRes>, 'queryKey'>,
): UseQueryResult<ITransactionsCountRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.TransactionKeys]
      .countTransactionsData(params) as unknown as UseQueryOptions<ITransactionsCountRes>),
  });

export const useGetTransactionDataQuery = ({
  sep24GUID,
  queryOptions
}: {
  sep24GUID: string;
  queryOptions?: Omit<UseQueryOptions<ITransactionDataRes>, 'queryKey'>;
}): UseQueryResult<ITransactionDataRes> =>
  useQuery({
    ...queryOptions,
    ...(queryKeysClient[EQueryKeys.TransactionKeys]
      .getTransactionData({ sep24GUID }) as unknown as UseQueryOptions<ITransactionDataRes>),
  });
