import { createQueryKeys } from '@lukemorales/query-key-factory';
import { IReqOptions } from 'common/clients/api.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  ITransactionDataRes,
  ITransactionsDataRes,
  ITransactionsDataParams,
  ITransactionInfoDataRes,
  ITransactionsCountRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  getTransactionsDataService,
  getTransactionDataService,
  getTransactionInfoDataService,
  countTransactionsService,
} from 'domains/transaction/services/transaction.service.ts';
import { TTransactionsCountReqParams } from 'domains/transaction/types/transaction.type.ts';

export const transactionQueryKeys = createQueryKeys(EQueryKeys.TransactionKeys, {
  getTransactionsData: ({
    reqParams,
    reqOptions,
  }: {
    reqParams: ITransactionsDataParams,
    reqOptions?: IReqOptions,
  }) => ({
    queryKey: ['get-transactions-data', reqParams],
    queryFn: ({ pageParam = 0 }: { pageParam: number }): Promise<ITransactionsDataRes> =>
      getTransactionsDataService({ pageParam, reqParams, reqOptions }),
  }),
  getTransactionData: ({
    sep24GUID,
    reqOptions,
  }: {
    sep24GUID: string;
    reqOptions?: IReqOptions;
  }) => ({
    queryKey: ['get-transaction-data', sep24GUID],
    queryFn: (): Promise<ITransactionDataRes> => getTransactionDataService({ sep24GUID, reqOptions }),
  }),
  getTransactionInfoData: ({
    sep24GUID,
    reqOptions,
  }: {
    sep24GUID: string;
    reqOptions?: IReqOptions;
  }) => ({
    queryKey: ['get-transaction-info-data', sep24GUID],
    queryFn: (): Promise<ITransactionInfoDataRes> => getTransactionInfoDataService({ sep24GUID, reqOptions }),
  }),
  // For general using and caching for Header and Rates
  getGeneralTransactionInfoData: ({
    sep24GUID,
    reqOptions,
  }: {
    sep24GUID: string;
    reqOptions?: IReqOptions;
  }) => ({
    queryKey: ['get-general-transaction-info-data', sep24GUID],
    queryFn: (): Promise<ITransactionInfoDataRes> => getTransactionInfoDataService({ sep24GUID, reqOptions }),
  }),
  countTransactionsData: (params: TTransactionsCountReqParams) => ({
    queryKey: ['count-transactions-data', params],
    queryFn: (): Promise<ITransactionsCountRes> => countTransactionsService(params),
  }),
});
