import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ESupportLinkType } from './enums/support-link.component.enum.ts';
import { SContainer } from './support-link.component.styles.ts';

interface ISupportLinkProps {
  id?: string;
  type: ESupportLinkType;
  data: {
    transactionId?: string | undefined,
    userId?: string | undefined,
    kind?: string | undefined,
    amount?: string,
    assetCode?: string,
    createdAt?: string
  };
}

const SupportLink = (props: ISupportLinkProps): ReactElement => {
  const {
    id,
    type,
    data: {
      transactionId,
      userId,
      kind,
      amount,
      assetCode,
      createdAt,
    },
  } = props;

  const MAILTO = 'mailto:help@aps.money';

  const transactionLinkGenerator = (
    transactionId?: string | undefined,
    kind?: string | undefined,
    amount?: string,
    assetCode?: string,
    createdAt?: string
  ): string => {
    const title = `Transaction ${transactionId}`;
    const bodyLine1 = 'Please help me to resolve an issue with the transaction.%0D%0A';
    const bodyLine2 = '%0D%0A';
    const bodyLine3 = '-----------------------------------------%0D%0A';
    const bodyLine4 = `trx ID: ${transactionId}%0D%0A`;
    const bodyLine5 = `kind: ${kind}%0D%0A`;
    const bodyLine6 = `amount: ${amount ?? '000'} ${assetCode ?? 'USD'}%0D%0A`;
    const bodyLine7 = `created at: ${createdAt ?? '01-01-0001 00:00 UTC'}%0D%0A`;
    const body = bodyLine1 + bodyLine2 + bodyLine3 + bodyLine4 + bodyLine5 + bodyLine6 + bodyLine7;

    return `${MAILTO}?subject=${title}&body=${body}`;
  };

  const kycLinkGenerator = (
    transactionId?: string | undefined,
    userId?: string | undefined,
    createdAt?: string
  ): string => {
    const title = `Issue with KYC for user ${userId ?? ''}`;
    const bodyLine1 = 'Please help me with KYC.%0D%0A';
    const bodyLine2 = '%0D%0A';
    const bodyLine3 = '-----------------------------------------%0D%0A';
    const bodyLine4 = transactionId ? `trx ID: ${transactionId}%0D%0A` : '';
    const bodyLine5 = `user ID: ${userId}%0D%0A`;
    const bodyLine6 = createdAt ? `created at: ${createdAt}%0D%0A` : '';
    const body = bodyLine1 + bodyLine2 + bodyLine3 + bodyLine4 + bodyLine5 + bodyLine6;

    return `${MAILTO}?subject=${title}&body=${body}`;
  };

  let supportLink: string;
  switch (type) {
    case ESupportLinkType.Transaction:
      supportLink = transactionLinkGenerator(transactionId, kind, amount, assetCode, createdAt);
      break;

    case ESupportLinkType.Kyc:
      supportLink = kycLinkGenerator(transactionId, userId, createdAt);
      break;

    default:
      supportLink = '<!!!please-specify-link-type!!!>';
      break;
  }


  return (
    <SContainer>
      <Trans
        i18nKey="supportLink"
        components={{
          supportLink: <Link id={id} to={supportLink} />,
        }}
      />
    </SContainer>
  );
};

SupportLink.defaultProps = {
  id: undefined,
};

export default SupportLink;
