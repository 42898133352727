import { ReactElement, useEffect, useState } from 'react';
import { Session } from '@ory/kratos-client';
import snsWebSdk from '@sumsub/websdk';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import Rates from 'common/components/rates/rates.component.tsx';
import Steps from 'common/components/steps/steps.component.tsx';
import { ESupportLinkType } from 'common/components/support-link/enums/support-link.component.enum.ts';
import SupportLink from 'common/components/support-link/support-link.component.tsx';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { authStartRecursivelyInteractiveService } from 'domains/auth/services/auth.service.ts';
import { usePutKycStatusMutation } from 'domains/kyc/queries/kyc.query.ts';
import { useGetTransactionDataQuery } from 'domains/transaction/queries/transaction.query.ts';
import { useGetUserProfileDataMutation } from 'domains/user/queries/user.query.ts';
import { EWallets } from 'domains/wallet/enums/wallet.enums.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { IKycPageLoaderData } from 'pages/kyc/interfaces/kyc.interfaces.ts';
import {
  SMainContainer,
  SBottomContainer,
  SIconWrapper,
  SNotificationTextWrapper,
  SWidget,
} from 'pages/kyc/kyc.page.styles.ts';
import AttentionIcon from 'assets/attention.icon.svg?react';

const KycPage = (): ReactElement => {
  const { t } = useTranslation();
  const { kycTokenData, walletData } = useLoaderData() as IKycPageLoaderData;
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id);
  const { mutateAsync: mutateAsyncPutKycStatusMutation } = usePutKycStatusMutation();
  const { mutateAsync: mutateAsyncGetUserProfileDatMutation } = useGetUserProfileDataMutation();
  const [themeName] = useAtom(themeNameAtom);
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const walletRawDataFromCache = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.WalletKeys].getWalletData._def,
  });
  const walletDataFromCache = walletRawDataFromCache[0][1] as IWalletDataRes;
  const sessionRawDataFromCache = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession._def,
  });
  const sessionDataFromCache = sessionRawDataFromCache[0][1] as Session;

  useEffect(() => {
    setHeaderConfig({
      backLink: '',
    });
  }, [setHeaderConfig]);

  const {
    data: transactionData,
  } = useGetTransactionDataQuery({
    sep24GUID: idQueryParamValue ?? '',
    queryOptions: {
      enabled: !!idQueryParamValue,
    }
  });

  const getToSession = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession._def,
  });
  const userData = getToSession[0][1] as Session;
  const { id: userGuid } = userData.identity ?? { id: '' };

  useEffect(() => {
    const accessToken = kycTokenData.data?.token ?? '';
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => Promise.resolve(accessToken))
      .withConf({
        lang: i18n.language,
        theme: themeName,
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.onInitialized', () => {
        setIsLoading(false);
      })
      .on('idCheck.onApplicantStatusChanged', async (payloadData) => {
        try {
          const payload = payloadData as {
            reviewStatus?: string;
            reviewResult?: { reviewAnswer?: string };
          };

          if (payload.reviewStatus === 'completed' && payload.reviewResult?.reviewAnswer === 'GREEN') {
            setIsLoading(true);

            const reqData = {
              interactive_id: idQueryParamValue ?? '',
              kyc_status: 'success',
            };

            const { return_to: returnTo, is_interactive: isInteractive } = await mutateAsyncPutKycStatusMutation({
              reqData,
            });

            if (returnTo) {
              const profileData = await mutateAsyncGetUserProfileDatMutation({});

              const identityTraits = sessionDataFromCache.identity?.traits as { email?: string } | undefined;
              const userEmail = identityTraits?.email ?? '';

              setSmartlookIdentity({
                interactiveID: idQueryParamValue ?? '',
                userIdentityId: sessionDataFromCache.identity?.id,
                userEmail,
                kyc_status: profileData.info.kyc_status,
                kyc_level: profileData.info.kyc_level,
                user_city: profileData.info.user_city,
                user_country: profileData.info.country_residence,
                wallet_name: walletDataFromCache.name,
              });

              if (isInteractive) {
                await authStartRecursivelyInteractiveService(setHttps(`${returnTo}?redirect=false`));
              } else {
                window.location.replace(setHttps(returnTo));
              }
            }
          }
        } catch (error) {
          captureError(error as IError);
          console.error('SumSub error: ', error);
        }
      })
      .on('idCheck.onError', (error) => {
        console.error('SumSub error: ', error);
        setIsLoading(false);
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }, [
    kycTokenData,
    idQueryParamValue,
    i18n.language,
    mutateAsyncPutKycStatusMutation,
    themeName,
    mutateAsyncGetUserProfileDatMutation,
    transactionData,
    walletDataFromCache,
    sessionDataFromCache,
  ]);

  return (
    <SMainContainer>
      {isLoading && <Loader fullscreen />}
      <Rates />
      <Steps current={1} />
      {walletData.key === EWallets.Beans && (
        <SNotificationTextWrapper>
          <SIconWrapper>
            <AttentionIcon />
          </SIconWrapper>
          <p className="p4">{t('kyc.pdf.error.notification')}</p>
        </SNotificationTextWrapper>
      )}
      <SWidget id="sumsub-websdk-container" />
      <SBottomContainer>
        <SupportLink
          type={ESupportLinkType.Kyc}
          data={{
            userId: userGuid,
            ...(transactionData?.id ? { transactionId: transactionData.id } : {}),
            ...(transactionData?.created_at ? { createdAt: transactionData.created_at } : {}),
          }}
        />
      </SBottomContainer>
    </SMainContainer>
  );
};

export default KycPage;
