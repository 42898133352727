import styled from 'styled-components';
import WarningIcon from 'assets/warning.icon.svg?react';

export const SContent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SWarningIcon = styled(WarningIcon)`
  path {
    stroke: ${(props) => props.theme.themeV3.colors.green300};
  }
`;

export const STitle = styled.h2`
  margin-top: 16px;
`;

export const SDescription = styled.p`
  margin-top: 16px;
  text-align: center;
`;

export const SButtonWrapper = styled.div`
  margin-top: auto;
  height: 56px;
`;
