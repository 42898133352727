import { Session } from '@ory/kratos-client';
import {
  FrontendApiGetRecoveryFlowRequest,
  FrontendApiUpdateLoginFlowRequest,
  FrontendApiUpdateRegistrationFlowRequest,
  FrontendApiUpdateRecoveryFlowRequest,
  FrontendApiUpdateSettingsFlowRequest,
  SuccessfulNativeRegistration,
  LoginFlow,
  RegistrationFlow,
  RecoveryFlow,
  SettingsFlow,
  SuccessfulNativeLogin,
  VerificationFlow,
  FrontendApiGetVerificationFlowRequest,
  FrontendApiUpdateVerificationFlowRequest,
  FrontendApiCreateBrowserLoginFlowRequest,
  FrontendApiCreateBrowserRecoveryFlowRequest,
  FrontendApiCreateBrowserRegistrationFlowRequest,
  FrontendApiGetRegistrationFlowRequest,
  FrontendApiGetLoginFlowRequest,
  FlowError,
} from '@ory/kratos-client/api';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { apiClient } from 'common/clients/api.client.ts';
import { mainStore } from 'common/clients/jotai-store.client.ts';
import { kratosClient } from 'common/clients/kratos.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { EAction } from 'domains/auth/enums/auth.enums.ts';
import { IDataResp } from 'domains/auth/interfaces/auth.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

export const authCreateLoginFlowService = async (
  params: FrontendApiCreateBrowserLoginFlowRequest
): Promise<LoginFlow> => {
  const { data } = await kratosClient.createBrowserLoginFlow(params);

  return data;
};

export const authUpdateLoginFlowService = async (
  reqData: FrontendApiUpdateLoginFlowRequest
): Promise<SuccessfulNativeLogin> => {
  const { data } = await kratosClient.updateLoginFlow(reqData);

  return data;
};

export const authGetLoginFlowService = async (
  reqData: FrontendApiGetLoginFlowRequest
): Promise<LoginFlow> => {
  const { data } = await kratosClient.getLoginFlow(reqData);

  return data;
};

export const authGetRegistrationFlowService = async (
  reqData: FrontendApiGetRegistrationFlowRequest
): Promise<RegistrationFlow> => {
  const { data } = await kratosClient.getRegistrationFlow(reqData);

  return data;
};

export const authCreateRegistrationFlowService = async (
  params: FrontendApiCreateBrowserRegistrationFlowRequest
): Promise<RegistrationFlow> => {
  const { data } = await kratosClient.createBrowserRegistrationFlow(params);

  return data;
};

export const authUpdateRegistrationFlowService = async (
  reqData: FrontendApiUpdateRegistrationFlowRequest
): Promise<SuccessfulNativeRegistration> => {
  const { data } = await kratosClient.updateRegistrationFlow(reqData);

  return data;
};

export const authGetVerificationFlowService = async (
  reqData: FrontendApiGetVerificationFlowRequest
): Promise<VerificationFlow> => {
  const { data } = await kratosClient.getVerificationFlow(reqData);

  return data;
};

export const authUpdateVerificationFlowService = async (
  reqData: FrontendApiUpdateVerificationFlowRequest
): Promise<VerificationFlow> => {
  const response = await kratosClient.updateVerificationFlow(reqData);

  return response.data;
};

export const authCreateRecoveryFlowService = async (
  params: FrontendApiCreateBrowserRecoveryFlowRequest
): Promise<RecoveryFlow> => {
  const { data } = await kratosClient.createBrowserRecoveryFlow(params);

  return data;
};

export const authUpdateRecoveryFlowService = async (
  reqData: FrontendApiUpdateRecoveryFlowRequest
): Promise<RecoveryFlow> => {
  const response = await kratosClient.updateRecoveryFlow(reqData);

  return response.data;
};

export const authGetRecoveryFlowService = async (
  reqData: FrontendApiGetRecoveryFlowRequest
): Promise<RecoveryFlow> => {
  const { data } = await kratosClient.getRecoveryFlow(reqData);

  return data;
};

export const authGetSettingsFlowService = async (flowId: string): Promise<SettingsFlow> => {
  const { data } = await kratosClient.getSettingsFlow({ id: flowId });

  return data;
};

export const authCreateSettingsFlowService = async (): Promise<SettingsFlow> => {
  const { data } = await kratosClient.createBrowserSettingsFlow();

  return data;
};

export const authUpdateSettingsFlowService = async (
  reqData: FrontendApiUpdateSettingsFlowRequest
): Promise<SettingsFlow> => {
  const { data } = await kratosClient.updateSettingsFlow(reqData);

  return data;
};

export const authToSessionService = async (): Promise<Session> => {
  const { data } = await kratosClient.toSession();

  return data;
};

export const authLogoutFlowService = async (navigate: NavigateFunction, redirect?: string): Promise<void> => {
  const { data: logoutFlow } = await kratosClient.createBrowserLogoutFlow();

  await kratosClient.updateLogoutFlow({
    token: logoutFlow.logout_token,
  });

  mainStore.set(profileConfigAtom, () => ({
    userIsAuthorized: false,
  }));

  localStorage.removeItem(ELocalStorageKeys.TransactionGUID);
  localStorage.removeItem(ELocalStorageKeys.TransactionSignature);
  queryClient.clear();

  if (redirect) {
    navigate(redirect);
  } else {
    navigate(ROUTES_MAPPING.PUBLIC.LOGIN);
  }
};

export const getFlowErrorService = async (errorId: string): Promise<FlowError> => {
  const { data } = await kratosClient.getFlowError({ id: errorId });

  return data;
};

export const lightWalkThroughInteractiveService = async (url: string): Promise<IDataResp> => {
  const { data } = await apiClient.get<IDataResp>(url);

  return data;
};

export const authStartRecursivelyInteractiveService = async (url: string): Promise<void> => {
  try {
    const { url: nextUrl, action } = await lightWalkThroughInteractiveService(url);

    if (nextUrl && action === EAction.Finish) {
      window.location.replace(setHttps(nextUrl));
    } else if (nextUrl) {
      await authStartRecursivelyInteractiveService(`${nextUrl}?redirect=false`);
    }
  } catch (error) {
    throw new Error(error as string);
  }
};
