import { ReactElement } from 'react';
import { InputProps } from 'antd';
import { SInput } from 'common/components/input/input.component.styles.ts';
import Cross2Icon from 'assets/cross-2.icon.svg?react';

type TProps = InputProps & {
  inputMode?: string;
}

const Input = (props: TProps): ReactElement => <SInput
  allowClear={{
    clearIcon: <Cross2Icon />,
  }}
  {...props}
/>;

Input.defaultProps = {
  inputMode: 'text',
};

export default Input;
