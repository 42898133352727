export enum ETransactionsDataParams {
  Kind = 'kind',
  Status = 'status',
  Blockchain = 'blockchain',
  WalletId = 'wallet_id',
  StartCreatedAt = 'start_created_at',
  EndCreatedAt = 'end_created_at',
}

export enum ETransactionKind {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export enum ETransactionFlowType {
  OffRamp = 'off-ramp',
  OnRamp = 'on-ramp',
}

export enum ETransactionStatus {
  Incomplete = 'incomplete',
  PendingUserTransferStart = 'pending_user_transfer_start',
  PendingUserTransferComplete = 'pending_user_transfer_complete',
  PendingAnchor = 'pending_anchor',
  PendingExternal = 'pending_external',
  PendingUser = 'pending_user',
  PendingStellar = 'pending_stellar',
  PendingTrust = 'pending_trust',
  Error = 'error',
  Completed = 'completed',
  Refunded = 'refunded',
  Expired = 'expired',

  // NoMarket = 'no_market',
  // TooSmall = 'too_small',
  // TooLarge = 'too_large',
}

export enum ETransactionState {
  FpfExpired = 'fpf-expired',
  H2hRefunded = 'h2h-refunded',
  FpfRefunded = 'fpf-refunded',
  FpfCreatedForRefund = 'fpf-created-for-refund',
  PostKycStarted = 'post-kyc-started',
}

export enum EBlockchainNetwork {
  Stellar = 'stellar',
  Tron = 'tron',
  Ethereum = 'ethereum',
}
