import { ReactElement } from 'react';
import { decodeToken } from 'react-jwt';
import { useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { ETransactionFlowType } from 'domains/transaction/enums/transaction.enums.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { SRateArrowIcon, SRatesContainer, SValue } from './rates.component.styles.ts';

const Rates = (): ReactElement | null => {
  const [searchParams] = useSearchParams();
  const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo) ?? '';
  let amountFromRedirectUrlQueryParamValue = '';

  if (returnToQueryParamValue) {
    const returnUrl = new URL(returnToQueryParamValue);
    const { searchParams: searchParamsReturnUrl } = returnUrl;
    amountFromRedirectUrlQueryParamValue = searchParamsReturnUrl.get(EUrlQueryParams.Amount) ?? '';
  }

  const amountParamValue = amountFromRedirectUrlQueryParamValue || (searchParams.get(EUrlQueryParams.Amount) ?? '');

  const queriesData = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.TransactionKeys].getGeneralTransactionInfoData._def,
  });
  const transactionSignature = localStorage.getItem(ELocalStorageKeys.TransactionSignature);

  let transactionData: Partial<ITransactionInfoDataRes> | undefined;

  if (queriesData[0]?.[1]) {
    transactionData = queriesData[0]?.[1] as ITransactionInfoDataRes;
  } else if (transactionSignature) {
    const signatureData = decodeToken<{
      payload: {
        base_currency_amount: string;
        base_currency_code: string;
        quote_currency_code: string;
        flow_type: ETransactionFlowType;
      }
    }>(transactionSignature);
    const flowType = signatureData?.payload.flow_type;
    const quoteCurrencyCode = signatureData?.payload.quote_currency_code;
    const baseCurrencyCode = signatureData?.payload.base_currency_code;

    transactionData = {
      amount_in: amountParamValue || signatureData?.payload.base_currency_amount,
      amount_in_asset: flowType === ETransactionFlowType.OnRamp ? quoteCurrencyCode : baseCurrencyCode,
    };
  }

  const amountIn = amountParamValue || transactionData?.amount_in;
  const amountInAsset = (transactionData?.amount_in_asset ?? '').split(':')[1];
  const isAmountIn = !!(Number(amountIn) && amountInAsset);
  const amountOut = amountParamValue || transactionData?.amount_out;
  const amountOutAsset = (transactionData?.amount_out_asset ?? '').split(':')[1];
  const isAmountOut = !!(Number(amountOut) && amountOutAsset);

  if (isAmountIn || isAmountOut) {
    return <SRatesContainer>
      {isAmountIn && (
        <SValue>{amountIn} {amountInAsset}</SValue>
      )}
      {(isAmountIn && isAmountOut) && (
        <SRateArrowIcon />
      )}
      {isAmountOut && (
        <SValue>{amountOut} {amountOutAsset}</SValue>
      )}
    </SRatesContainer>;
  }

  return null;
};

export default Rates;
