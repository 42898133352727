import { apiClient, IReqOptions } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { IKycStatusReq, IKycStatusRes, IKycTokenRes } from 'domains/kyc/interfaces/kyc.interfaces.ts';

export const getKycTokenService = async ({
  appId,
  reqOptions,
}: {
  appId?: string;
  reqOptions?: IReqOptions;
}): Promise<IKycTokenRes> => {
  const url = API_ENDPOINTS.KYC.TOKEN;
  const config = {
    reqOptions,
    params: {
      ...(appId ? { [EUrlQueryParams.AppId]: appId } : {}),
    },
  };
  const res = await apiClient.get<IKycTokenRes>(url, config);

  return res.data;
};

export const putKycStatusTokenService = async (data: IKycStatusReq): Promise<IKycStatusRes> => {
  const url = API_ENDPOINTS.KYC.STATUS;
  const res = await apiClient.put<IKycStatusRes>(url, data);

  return res.data;
};
