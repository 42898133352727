import { AxiosRequestConfig } from 'axios';
import { apiClient, IReqOptions } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import {
  IUserBillingFieldsRes,
  IUserPutBillingFieldsData,
  IUserPutBillingFieldsRes,
} from 'domains/user-billing/interfaces/user-billing.interfaces.ts';

export const getUserBillingFieldsService = async ({
    reqOptions,
  } : {
  reqOptions?: IReqOptions;
}): Promise<IUserBillingFieldsRes> => {
  const url = API_ENDPOINTS.USER.BILLING_FIELDS;
  const config = {
    ...reqOptions,
  } as AxiosRequestConfig;
  const res = await apiClient.get<IUserBillingFieldsRes>(url, config);

  return res.data;
};

export const putUserBillingFieldsService = async (
  data: IUserPutBillingFieldsData
): Promise<IUserPutBillingFieldsRes> => {
  const url = API_ENDPOINTS.USER.BILLING_FIELDS;
  const res = await apiClient.put<IUserPutBillingFieldsRes>(url, data);

  return res.data;
};
