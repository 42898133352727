import {
  ChangeEvent,
  ReactElement,
  RefAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { InputProps, InputRef } from 'antd';
import IMask, { MaskedOptions } from 'imask';
import { FactoryArg, FactoryOpts } from 'imask/masked/factory';
import {
  SInput,
  SInputClearIconWrapper,
  SInputWrapper,
} from 'common/components/input-mask/input-mask.component.styles.ts';
import Cross2Icon from 'assets/cross-2.icon.svg?react';

export interface IInputMaskValue {
  unmaskedValue: string;
  maskedValue: string;
}

type IInputPropsType = InputProps &
  RefAttributes<InputRef> & {
  mask: MaskedOptions['mask'];
  maskOptions?: FactoryOpts;
  value?: IInputMaskValue | undefined;
  defaultValue?: string | undefined;
};

export interface IInputMaskChangeEvent extends ChangeEvent<HTMLInputElement>, IInputMaskValue {}

const InputMask = (props: IInputPropsType): ReactElement => {
  const {
    value,
    defaultValue,
    mask,
    maskOptions: _maskOptions,
    ...restProps
  } = props;

  const inputRef = useRef<HTMLInputElement & { input: { value: string }}>(null);
  const maskOptions = {
    mask,
    ..._maskOptions,
  } as FactoryArg;
  const masked = {
    mask: IMask.createPipe(maskOptions),
    unmask: (value: string): string => IMask.pipe(value, maskOptions, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.UNMASKED),
  };
  const maskedValue = masked.mask(value?.unmaskedValue ?? defaultValue ?? '');
  const [fieldValue, setFieldValue] = useState<string>(value?.unmaskedValue ?? '');
  const [maskedFieldValue, setMaskedFieldValue] = useState<string>(maskedValue);

  useEffect(() => {
    const originalMask = masked.mask('');
    setMaskedFieldValue(originalMask);

    if (fieldValue) {
      if (restProps.onChange) {
        restProps.onChange({
          unmaskedValue: '',
          maskedValue: '',
        } as IInputMaskChangeEvent);
      }

      setFieldValue('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mask]);

  const getLastDigitPosition = (arr: string[]): number => {
    for (let i = arr.length - 1; i >= 0; i -= 1) {
      if (/[0-9]/.test(arr[i])) {
        return i;
      }
    }

    return -1;
  };

  useEffect(() => {
    if (inputRef.current && fieldValue) {
      const fieldValueToArray = maskedFieldValue.split('');
      const lastDigitIndex = getLastDigitPosition(fieldValueToArray) + 1;

      inputRef.current.setSelectionRange(lastDigitIndex, lastDigitIndex);
    }
  }, [fieldValue, maskedFieldValue]);

  const onChangeHandler = (e: IInputMaskChangeEvent): void => {
    const input = e.target;
    const maskedValue = masked.mask(input.value);
    const currentValue = masked.unmask(maskedValue);

    setFieldValue(currentValue);
    setMaskedFieldValue(maskedValue);

    const maskedValueToArray = maskedValue.split('');
    const maskedValueArray = maskedValueToArray.slice(0, getLastDigitPosition(maskedValueToArray) + 1);

    if (restProps.onChange) {
      restProps.onChange({
        unmaskedValue: currentValue,
        maskedValue: maskedValueArray.join(''),
      } as IInputMaskChangeEvent);
    }
  };

  const onFocusHandler = (): void => {
    setTimeout(() => {
      if (inputRef.current) {
        const fieldValueToArray = maskedFieldValue.split('');
        const lastDigitIndex = getLastDigitPosition(fieldValueToArray) + 1;

        if (lastDigitIndex) {
          inputRef.current.setSelectionRange(lastDigitIndex, lastDigitIndex);
        } else {
          inputRef.current.setSelectionRange(0, 0);
        }
      }
    });
  };

  return (
    <SInputWrapper>
      <SInput
        {...restProps}
        // @ts-expect-error Hard to resolve types
        ref={inputRef}
        value={maskedFieldValue}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        isClear={!!fieldValue}
      />
      {fieldValue && (
        <SInputClearIconWrapper
          className="input-mask-clear-icon"
          onClick={() => {
            const originalMask = masked.mask('');
            setMaskedFieldValue(originalMask);
            setFieldValue('');

            if (restProps.onChange) {
              restProps.onChange({
                unmaskedValue: '',
                maskedValue: '',
              } as IInputMaskChangeEvent);
            }
          }}
        >
          <Cross2Icon />
        </SInputClearIconWrapper>
      )}
    </SInputWrapper>
  );
};

InputMask.defaultProps = {
  maskOptions: {
    lazy: false,
  },
  value: undefined,
  defaultValue: undefined,
};

export default InputMask;
