import { ReactElement } from 'react';
import { StepsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { SSteps, SStepsContainer } from './steps.component.styles.ts';

const Steps = (props: StepsProps): ReactElement => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('components.onboardingSteps.1'),
    },
    {
      title: t('components.onboardingSteps.2'),
    },
    {
      title: t('components.onboardingSteps.3'),
    },
  ];

  return (
    <SStepsContainer>
      <SSteps items={items} responsive={false} {...props} />
    </SStepsContainer>
  );
};

export default Steps;
