import { EWallets } from 'domains/wallet/enums/wallet.enums.ts';
import BeansAppWalletIcon from 'assets/wallet-logo-icons/beans-app.icon.svg?react';
import DefaultMerchantIcon from 'assets/wallet-logo-icons/default.icon.svg?react';
import LobstrWalletIcon from 'assets/wallet-logo-icons/lobstr.icon.svg?react';
import TrustWalletIcon from 'assets/wallet-logo-icons/trust-wallet.icon.svg?react';

export const WALLETS_MAPPING = {
  [EWallets.Beans]: {
    icon: <BeansAppWalletIcon />,
  },
  [EWallets.Lobstr]: {
    icon: <LobstrWalletIcon />,
  },
  [EWallets.TrustWallet]: {
    icon: <TrustWalletIcon />,
  },
  [EWallets.Test]: {
    icon: <DefaultMerchantIcon />,
  },
};
