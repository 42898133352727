import styled from 'styled-components';
import { Radio } from 'antd';
import BackArrowIcon from 'assets/main-menu/back-arrow.icon.svg?react';
import CloseIcon from 'assets/main-menu/close.icon.svg?react';
import SelectedIcon from 'assets/main-menu/selected.icon.svg';
import { EThemes } from 'theme/enums/theme.enums.ts';

export const SMainMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999;
  background: ${props => {
    if (props.theme.themeName === EThemes.Light) {
      return props.theme.themeV3.colors.grey200;
    }
    
    return props.theme.themeV3.colors.grey100;
  }};
`;

export const SMainMenuHeader = styled.div`
  border-radius: 0 0 16px 16px;
  background: ${props => props.theme.themeV3.colors.grey100};
  display: flex;
  padding: 32px 16px 16px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const SMainMenuHeaderBackIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const SMainMenuHeaderBackIcon = styled(BackArrowIcon)`
  cursor: pointer;
  
  path {
    stroke: ${props => props.theme.themeV3.colors.grey700};
  }
`;

export const SMainMenuHeaderLabel = styled.div`
  color: ${props => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const SCloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const SMainMenuHeaderCloseIcon = styled(CloseIcon)`
  cursor: pointer;

  path {
    stroke: ${props => props.theme.themeV3.colors.grey700};
  }
`;

export const SMainMenuScreenContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const SMainMenuScreenTitle = styled.h2`
  
`;

export const SMainMenuCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: ${props => props.theme.themeV3.colors.grey100};
  padding: 12px 16px;
  gap: 12px;
  width: 100%;

  background: ${props => {
    if (props.theme.themeName === EThemes.Light) {
      return props.theme.themeV3.colors.grey100;
    }

    return props.theme.themeV3.colors.grey200;
  }};
`;

export const SMainMenuSelectorGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const SMainMenuSelector = styled(Radio)`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-inline: 0;
  
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    padding-inline: 0 !important;
    gap: 16px;
    width: 100%;
  }
  
  &:after {
    display: none;
  }
  
  .ant-radio {
    display: none;
  }
  
  &.ant-radio-wrapper-checked {
    span {
      .p3 {
        &:after {
          content: '';
          background: url(${SelectedIcon});
          width: 20px;
          height: 20px;
          display: block;
        }
      }
    }
  }
`;

export const SMainMenuSelectorSimpleIconWrapper = styled.div`
  height: 24px;
  overflow: hidden;
  border-radius: 4px;
  
  svg {
    width: auto;
    height: 24px;
  }
`;

export const SMainMenuSelectorFilledIconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.themeV3.colors.grey200};

  svg {
    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    };
  }
`;

export const SMainMenuSelectorLabel = styled.p`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
