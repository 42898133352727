import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { ETransactionsDataParams, ETransactionStatus } from 'domains/transaction/enums/transaction.enums.ts';
import { ITransactionsDataParams } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { FILTER_STATUS_MAPPING } from 'pages/transactions/constants/transactions.constants.ts';
import { IFilterParams, IFilterParamsFromLocalStorage } from 'pages/transactions/interfaces/transactions.interfaces.ts';

const getFilterParamsUtil = (
  url: string
): ITransactionsDataParams | Record<string, never> => {
  let params: IFilterParams | Record<string, never> = {};

  const urlSearchParams = new URL(url).searchParams;
  const kindQueryParamValue = urlSearchParams.get(ETransactionsDataParams.Kind);
  const statusQueryParamValue = urlSearchParams.get(ETransactionsDataParams.Status);
  const blockchainQueryParamValue = urlSearchParams.get(ETransactionsDataParams.Blockchain);
  const walletIdQueryParamValue = urlSearchParams.get(ETransactionsDataParams.WalletId);
  const startCreatedAtQueryParamValue = urlSearchParams.get(ETransactionsDataParams.StartCreatedAt);
  const endCreatedAtQueryParamValue = urlSearchParams.get(ETransactionsDataParams.EndCreatedAt);

  // Create filter data from query params
  const formatedFiltersDataFromQueryParams: IFilterParams | Record<string, never> = {
    ...(kindQueryParamValue ? { [ETransactionsDataParams.Kind]: kindQueryParamValue } : {}),
    ...(statusQueryParamValue ? { [ETransactionsDataParams.Status]: statusQueryParamValue } : {}),
    ...(blockchainQueryParamValue ? { [ETransactionsDataParams.Blockchain]: blockchainQueryParamValue } : {}),
    ...(walletIdQueryParamValue ? { [ETransactionsDataParams.WalletId]: walletIdQueryParamValue } : {}),
    ...(startCreatedAtQueryParamValue
        ? { [ETransactionsDataParams.StartCreatedAt]: Number(startCreatedAtQueryParamValue) }
        : {}
    ),
    ...(endCreatedAtQueryParamValue
        ? { [ETransactionsDataParams.EndCreatedAt]: Number(endCreatedAtQueryParamValue) }
        : {}
    ),
  };

  // Create filter data from localStorage.
  const getFiltersDataFromLocalStore = localStorage.getItem(ELocalStorageKeys.TransactionFilters);
  let formatedFiltersDataFromLocalStorage: IFilterParams | Record<string, never> = {};

  if (getFiltersDataFromLocalStore) {
    const filtersDataFromLocalStore = JSON.parse(getFiltersDataFromLocalStore) as IFilterParamsFromLocalStorage;
    const filtersDataFromLocalStoreToEntries =
      Object.entries(filtersDataFromLocalStore) as [ETransactionsDataParams, string[] | number][];

    formatedFiltersDataFromLocalStorage = filtersDataFromLocalStoreToEntries
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? value.toString() : value,
      }), {});
  }

  params = (Object.keys(formatedFiltersDataFromQueryParams).length ? formatedFiltersDataFromQueryParams : 0)
    || (Object.keys(formatedFiltersDataFromLocalStorage).length ? formatedFiltersDataFromLocalStorage : {});

  // Set default params.
  if (!Object.keys(params).length) {
    const defaultStatusesValue = FILTER_STATUS_MAPPING
      .filter((filter: { value: unknown }) => filter.value !== ETransactionStatus.Incomplete)
      .map((filter) => filter.value)
      .toString();

    params = {
      [ETransactionsDataParams.Status]: defaultStatusesValue,
    };
  }

  return params;
};

export default getFilterParamsUtil;
