import { decodeToken } from 'react-jwt';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { ETransactionStatus } from 'domains/transaction/enums/transaction.enums.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import { IFinalStepPageLoaderData } from './interfaces/final-step.interfaces.ts';

export const finalStepPageLoader = async (
  { request }: LoaderFunctionArgs
): Promise<IFinalStepPageLoaderData | Response> => {
  try {
    const reqOptions = {
      from: request.url,
    };
    const { searchParams } = new URL(request.url);
    const idQueryParamValue = searchParams.get(EUrlQueryParams.Id) ?? '';
    const transactionQueryParamValue = searchParams.get(EUrlQueryParams.Transaction) ?? '';

    if (!idQueryParamValue) {
      return redirect(ROUTES_MAPPING.NO_MATCH);
    }

    let transactionData: ITransactionInfoDataRes | Record<string, never> = {};

    if (transactionQueryParamValue) {
      const transactionToken = decodeToken<{
        payload: {
          transaction: ITransactionInfoDataRes,
        }
      }>(transactionQueryParamValue);
      transactionData = transactionToken?.payload.transaction ?? {};
    } else {
      const transactionIdFromLocalStorage = localStorage.getItem(ELocalStorageKeys.TransactionGUID) ?? '';
      const transactionId = transactionIdFromLocalStorage || idQueryParamValue;
      const transactionDataQuery =
        queryKeysClient[EQueryKeys.TransactionKeys].getGeneralTransactionInfoData({
          sep24GUID: transactionId,
          reqOptions,
        });
      transactionData = (await queryClient.getQueryData(transactionDataQuery.queryKey))
        ?? (await queryClient.fetchQuery(transactionDataQuery));
    }

    if (transactionData.status === ETransactionStatus.Error) {
      return redirect(ROUTES_MAPPING.ERROR);
    }

    return {
      transactionData,
    };
  } catch (e: unknown) {
    const error = e as {
      response: {
        status: number;
      };
    };

    captureError(error as IError);

    if (error.response.status === 404) {
      return redirect(ROUTES_MAPPING.NO_MATCH);
    }

    return {
      transactionData: {},
    };
  }
};
