import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker } from 'antd';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

export const GlobalStyle = createGlobalStyle`
  .custom-date-picker .ant-picker-panel-container  {
    background: ${(props) => props.theme.themeV3.colors.grey100};
  }

  .custom-date-picker .ant-picker-header {
    color: ${(props) => props.theme.themeV3.colors.grey700};

    .ant-picker-header-super-prev-btn, 
    .ant-picker-header-next-btn {
      color: ${(props) => props.theme.themeV3.colors.grey400};

        &:hover {
        color: ${(props) => props.theme.themeV3.colors.grey600}
      }
    }

    .ant-picker-header-prev-btn, 
    .ant-picker-header-super-next-btn {
      color: ${(props) => props.theme.themeV3.colors.grey400};

        &:hover {
        color: ${(props) => props.theme.themeV3.colors.grey600}
      }
    }
  }

  .custom-date-picker .ant-picker-content th {
    color: ${(props) => props.theme.themeV3.colors.grey400};
  }

  .custom-date-picker .ant-picker-content td {
    color: ${(props) => props.theme.themeV3.colors.grey400};
  }

  .custom-date-picker .ant-picker-cell {
    &.ant-picker-cell-in-view {
      color: ${(props) => props.theme.themeV3.colors.grey700}; 
    }

    &.ant-picker-cell-disabled {
      color: ${(props) => props.theme.themeV3.colors.grey300};
    }    
`;

export const SDatePicker = styled(MyDatePicker)`
  width: 100%;
  border-radius: 8px;
  height: 56px;
  border: 0;
  background: ${props => props.theme.themeV3.colors.grey200};
  padding: 16px;

  &:hover,
  &:focus,
  &:focus-within {
    border: 0;
    color: ${props => props.theme.themeV3.colors.grey700};
    background: ${props => props.theme.themeV3.colors.grey200};
    box-shadow: none;
  }
  
  .ant-picker-input {
    display: flex;
    
    input {
      color: ${props => props.theme.themeV3.colors.grey700};
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      
      &::placeholder {
        color: ${props => props.theme.themeV3.colors.grey400};
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    
    .ant-picker-suffix {
      svg {
        path {
          fill: ${props => props.theme.themeV3.colors.grey700};
        }
      }
    }
  }
`;
