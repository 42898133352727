import { ReactElement } from 'react';
import { FormItemProps } from 'antd';
import { SFormItem } from 'common/components/form-item/form-item.component.styles.ts';

const FormItem = (props: FormItemProps): ReactElement => (
  <SFormItem
    validateDebounce={500}
    {...props}
  />);

export default FormItem;
