import styled from 'styled-components';

export const SNotificationTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding-top: 32px;
`;

export const SIconWrapper = styled.div`
  width: 16px; 
  height: 16px;
`;

export const SMainContainer = styled.div`
  padding-top: 16px;
`;

export const SWidget = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const SBottomContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
