import { useMutation, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  IUserProfileConfigReqData,
  IUserProfileConfigRes,
  IUserProfileDataRes,
} from 'domains/user/interfaces/user.interfaces.ts';
import { getUserProfileDataService, postUserProfileConfigService } from 'domains/user/services/user.service.ts';

export const useGetUserProfileDataQuery = (
  options?: Omit<UseQueryOptions<IUserProfileDataRes>, 'queryKey'>,
): UseQueryResult<IUserProfileDataRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.UserKeys]
      .getUserProfileData() as unknown as UseQueryOptions<IUserProfileDataRes>),
  });

export const useGetUserProfileDataMutation = (): UseMutationResult<IUserProfileDataRes> =>
  useMutation({
    mutationFn: () => getUserProfileDataService(),
  });

export const usePostUserProfileConfigMutation = (): UseMutationResult<
  IUserProfileConfigRes,
  Error,
  { reqData: IUserProfileConfigReqData }
> =>
  useMutation({
    mutationFn: ({ reqData }: { reqData: IUserProfileConfigReqData }) =>
      postUserProfileConfigService(reqData),
  });
