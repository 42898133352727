import { Steps } from 'antd';
import styled from 'styled-components';
import checkbox from 'assets/stepper-done.icon.svg';

export const SStepsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SSteps = styled(Steps)`
  &.ant-steps-horizontal {
    width: 312px;
    
    .ant-steps-item {
      padding-inline-start: 0 !important;

      &:last-child {
        .ant-steps-item-container {
          .ant-steps-item-content {
            &:after {
              display: none;
            }
          }
        }
      }
      
      &.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-content {
            .ant-steps-item-title {
              color: ${(props) => props.theme.themeV3.colors.green400};
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 8px;
              
              &:after {
                content: '';
                width: 12px;
                height: 12px;
                display: block;
                inset-inline-start: auto;
                position: relative;
                top: 0;
                background-color: transparent;
                background-image: url(${checkbox});
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }
          }
        }
      }

      &.ant-steps-item-active  {
        .ant-steps-item-container {
          .ant-steps-item-content {
            .ant-steps-item-title {
              color: ${(props) => props.theme.themeV3.colors.grey700};
            }
          }
        }
      }
      
      .ant-steps-item-container {
        .ant-steps-item-icon {
          display: none;
        }
        
        .ant-steps-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          &:after {
            content: '';
            display: block;
            height: 2px;
            border-radius: 2px;
            background: ${props => props.theme.themeV3.colors.grey200};
            width: 24px;
          }
          
          .ant-steps-item-title {
            width: 88px;
            padding: 0 8px;
            text-align: center;
            color: ${props => props.theme.themeV3.colors.grey200};
            font-variant-numeric: lining-nums tabular-nums;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;
