import { LoaderFunctionArgs } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { IUserBillingFieldsRes } from 'domains/user-billing/interfaces/user-billing.interfaces.ts';

export const billingDetailsPageLoader = async ({ request }: LoaderFunctionArgs): Promise<{
  userBillingFieldsData: IUserBillingFieldsRes | object;
}> => {
  try {
    const reqOptions = {
      from: request.url,
    };
    const getUserBillingFieldsQuery = queryKeysClient[EQueryKeys.UserBillingQueryKeys].getUserBillingFields({
      reqOptions,
    });
    const userBillingFieldsData: IUserBillingFieldsRes =
      await queryClient.getQueryData(getUserBillingFieldsQuery.queryKey)
      ?? await queryClient.fetchQuery(getUserBillingFieldsQuery);

    return {
      userBillingFieldsData,
    };
  } catch (error: unknown) {
    captureError(error as IError);

    return {
      userBillingFieldsData: {},
    };
  }
};
