import { ReactElement } from 'react';
import CalendarIcon from 'assets/calendar.icon.svg?react';
import { SDatePicker, GlobalStyle } from './date-picker.component.styles.ts';

// @ts-expect-error wrong ANTD types
const DatePicker = (props): ReactElement => (
  <>
    <GlobalStyle />
    <SDatePicker suffixIcon={<CalendarIcon />} popupClassName="custom-date-picker" {...props} />
  </>
);

export default DatePicker;
