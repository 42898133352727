export const ROUTES_MAPPING = {
  ROOT: '/',
  NO_MATCH: '/404', // DO NOT CHANGE! ORY SETTING!
  ERROR: '/error', // DO NOT CHANGE! ORY SETTING!
  DECISION_FLOW: '/decision-flow', // DO NOT CHANGE! ORY SETTING!
  FPF_ORDER_FORM: '/order-form', // DO NOT CHANGE! ORY SETTING!
  FINAL_STEP: '/final-step', // DO NOT CHANGE! ORY SETTING!
  TEST_SENTRY_ERROR:'/test-sentry-error',
  PUBLIC: {
    LOGIN: '/login', // DO NOT CHANGE! ORY SETTING!
    OTP_CODE_VERIFICATION: '/verification', // DO NOT CHANGE! ORY SETTING!
    TERMS_AND_CONDITIONS : '/terms-and-conditions',
    PRIVACY_AND_COOKIES_POLICY: '/privacy-and-cookies-policy',
  },
  PROTECTED: {
    INTERACTIVE_FLOW: '/interactive-flow', // DO NOT CHANGE! ORY SETTING!
    BILLING_DETAILS: '/billing-details', // DO NOT CHANGE! ORY SETTING!
    KYC: '/kyc', // DO NOT CHANGE! ORY SETTING!
    FPF_PAYMENT: '/payment', // DO NOT CHANGE! ORY SETTING!
    TRANSACTIONS: '/transactions',
    TRANSACTION: '/transaction', // DO NOT CHANGE! ORY SETTING!
  },
};
