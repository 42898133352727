import {
  InfiniteData
} from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import {
  ITransactionsDataRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { IUserWalletsDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import getFilterParamsUtil from 'pages/transactions/utils/get-filter-params.util.ts';
import { IFilterParams, ITransactionsPageLoaderData } from './interfaces/transactions.interfaces.ts';

export const transactionsPageLoader = async (
  { request }: LoaderFunctionArgs
): Promise<ITransactionsPageLoaderData | Response> => {
  try {
    const reqOptions = {
      from: request.url,
    };
    const PAGE_LIMIT = 20;
    let params: IFilterParams | Record<string, never> = {};
    params = getFilterParamsUtil(request.url);

    const transactionsReqParams = {
      ...params,
      limit: PAGE_LIMIT,
    };
    const transactionsDataQuery = queryKeysClient[EQueryKeys.TransactionKeys]
      .getTransactionsData({
        reqParams: transactionsReqParams,
        reqOptions,
      });
    const transactionsData: InfiniteData<ITransactionsDataRes> =
      // @ts-expect-error Hard to resolve
      await queryClient.fetchInfiniteQuery(transactionsDataQuery);

    const walletsDataQuery = queryKeysClient[EQueryKeys.WalletKeys].getUserWalletsData({
      reqOptions,
    });
    const walletsData: IUserWalletsDataRes = await queryClient.getQueryData(walletsDataQuery.queryKey)
      ?? await queryClient.fetchQuery(walletsDataQuery);

    return {
      initialParams: params,
      transactionsData,
      walletsData,
    };
  } catch (error: unknown) {
    captureError(error as IError);

    return {
      initialParams: {},
      transactionsData: undefined,
      walletsData: {},
    };
  }
};
