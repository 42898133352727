import { createQueryKeys } from '@lukemorales/query-key-factory';
import { IReqOptions } from 'common/clients/api.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { IKycTokenRes } from 'domains/kyc/interfaces/kyc.interfaces.ts';
import { getKycTokenService } from 'domains/kyc/services/kyc.service.ts';

export const kycQueryKeys = createQueryKeys(EQueryKeys.KycQueryKeys, {
  getKycToken: ({
    appId,
    reqOptions,
  }: {
    appId?: string;
    reqOptions?: IReqOptions;
  }) => ({
    queryKey: ['get-kyc-token', appId],
    queryFn: (): Promise<IKycTokenRes> => getKycTokenService({ appId, reqOptions }),
  }),
});
