import { ReactElement, useEffect } from 'react';
import { useSetAtom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import { useLocation, useNavigate } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import {
  SContainer,
  SLink,
  SLinkContainer,
  SMenuContainer, SMenuIcon,
  STitle,
} from 'common/components/header/header.component.styles.ts';
import { EScreens } from 'common/components/main-menu/enums/main.menu.enums.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { headerConfigAtom, headerConfigDefaultValue } from 'common/stores/header.store.ts';
import { mainMenuScreenAtom } from 'common/stores/main-menu.store.ts';
import { ETransactionFlowType, ETransactionKind } from 'domains/transaction/enums/transaction.enums.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import BackArrowIcon from 'assets/back-arrow.icon.svg?react';

const Header = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const setMainMenuScreen = useSetAtom(mainMenuScreenAtom);
  const [headerConfigData, setHeaderConfig] = useAtom(headerConfigAtom);
  const {
    backLink,
    title,
    isMenuVisible = true,
  } = headerConfigData;
  const queriesData = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.TransactionKeys].getGeneralTransactionInfoData._def,
  });

  useEffect(() => () => {
    setHeaderConfig(headerConfigDefaultValue);
  }, [location, setHeaderConfig]);

  const transactionSignature = localStorage.getItem(ELocalStorageKeys.TransactionSignature);

  let transactionData: Partial<ITransactionInfoDataRes> | undefined;

  if (queriesData[0]?.[1]) {
    transactionData = queriesData[0]?.[1] as ITransactionInfoDataRes;
  } else if (transactionSignature) {
    const signatureData = decodeToken<{
      payload: {
        base_currency_amount: string;
        base_currency_code: string;
        quote_currency_code: string;
        flow_type: ETransactionFlowType;
      }
    }>(transactionSignature);
    const flowType = signatureData?.payload.flow_type;
    const quoteCurrencyCode = signatureData?.payload.quote_currency_code;
    const baseCurrencyCode = signatureData?.payload.base_currency_code;

    transactionData = {
      kind: flowType === ETransactionFlowType.OnRamp ? ETransactionKind.Deposit : ETransactionKind.Withdrawal,
      amount_in_asset: quoteCurrencyCode,
      amount_out_asset: baseCurrencyCode,
    };
  }

  let kind = '';

  if (transactionData?.kind) {
    kind = transactionData.kind === ETransactionKind.Deposit
      ? t('transaction.type.deposit')
      : t('transaction.type.withdrawal');
  }

  const getAmountAsset =
    transactionData?.kind === ETransactionKind.Deposit
      ? transactionData.amount_out_asset
      : transactionData?.amount_in_asset;
  const amountInAsset = (getAmountAsset ?? '').split(':')[1];

  const renderTitle = (): ReactElement | null => {
    if (title !== false) {
      if (title !== undefined && title !== null) {
        return <STitle className="p2">{t(title)}</STitle>;
      }

      return <STitle className="p2">{kind} {amountInAsset}</STitle>;
    }

    return null;
  };

  return (
    <SContainer>
      <SLinkContainer>
        {backLink && (
          <SLink
            id="navigate-back"
            onClick={() => {
              if (typeof backLink === 'string' || typeof backLink === 'number') {
                navigate(backLink as unknown as string);
              } else {
                backLink();
              }
            }}
          >
            <BackArrowIcon />
          </SLink>
        )}
      </SLinkContainer>
      <div>
        {renderTitle()}
      </div>
      <SMenuContainer id="main-menu">
        {isMenuVisible && (
          <SMenuIcon
            onClick={() => {
              setMainMenuScreen(EScreens.Main);
            }}
          />
        )}
      </SMenuContainer>
    </SContainer>
  );
};

export default Header;
