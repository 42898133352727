import { AxiosRequestConfig } from 'axios';
import { apiClient, IReqOptions } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import {
  IUserProfileConfigReqData,
  IUserProfileConfigRes,
  IUserProfileDataRes,
} from 'domains/user/interfaces/user.interfaces.ts';

export const getUserProfileDataService = async (): Promise<IUserProfileDataRes> => {
  const url = API_ENDPOINTS.USER.PROFILE;
  const res = await apiClient.get<IUserProfileDataRes>(url);

  return res.data;
};

export const getUserProfileConfigService = async ({
  reqOptions,
}: {
  reqOptions: IReqOptions;
}): Promise<IUserProfileConfigRes> => {
  const url = API_ENDPOINTS.USER.CONFIG;
  const config = {
    ...reqOptions,
  } as AxiosRequestConfig;
  const res = await apiClient.get<IUserProfileConfigRes>(url, config);

  return res.data;
};

export const postUserProfileConfigService = async (data: IUserProfileConfigReqData): Promise<IUserProfileConfigRes> => {
  const url = API_ENDPOINTS.USER.CONFIG;
  const res = await apiClient.post<IUserProfileConfigRes>(url, data);

  return res.data;
};
